import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Container,
  Title,
  Content,
  Button,
  Grid,
  Card,
  Image,
  List,
  A
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Blog Online-Marketing & Webentwicklung - Sascha Klapetz"
      description="► Themen: ✓ User Experience (UX) ✓ Suchmaschinenoptimierung (SEO)  ✓ Conversion Rate Optimierung (CRO) ✓ Pagespeed-Optimierung ✓ Webentwicklung"
      robots="noindex"
    />
    <Container>
      <Container.Inner narrow>
        <Title as="h1">Hi, Instagrammer!</Title>
        <Content>
          <List>
            {data.allMarkdownRemark.edges.map(({ node }) =>
              <List.Item key={node.id}><Title smaller><A as={Link} to={node.fields.slug} colored="true">{node.frontmatter.title}</A></Title></List.Item>
            )}
            <List.Item><Title smaller><A as={Link} to="/blog/" colored="true">... weitere Beiträge</A></Title>
              </List.Item>
            <List.Item><Title smaller><A as={Link} to="/leistungen/" colored="true">Leistungen ansehen</A></Title>
              </List.Item>
            <List.Item><Title smaller><A as={Link} to="/kontakt/" colored="true">Kontakt aufnehmen</A></Title>
              </List.Item>
          </List>
        </Content>
      </Container.Inner>
    </Container>
     
  </Layout>
)}

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3,
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        fileAbsolutePath: {regex: "\/blog/"},
        frontmatter: {draft: {ne: true}}
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            image {
              ...postImage
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
